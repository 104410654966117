<template>
  <div view="dashboard">
    <button class="create" @mouseup="buttonMouseUp">Create <i class="las la-plus"></i><i class="las la-angle-down"></i></button>
    <div class="create-menu">
      <ul>
        <router-link to="/create/table"><li><i class="las la-database"></i>Create Table</li></router-link>
        <router-link to="/create/dataset"><li><i class="las la-stream"></i>Create Data Set</li></router-link>
      </ul>
    </div>
    <div class="dashboard-row">
      <div v-if="tables.length" class="dashboard-cell">
        <div class="dashboard-cell-title">
          <h1>My Tables</h1>
        </div>
        <hr>
        <div class="dashboard-cell-content">
          <ul class="dashboard-cell-list">
            <li v-for="table in tables">
              <div>{{ table.table_name }} <small>({{ table.table_slug }})</small> <button class="cell">View</button></div>
              <div><small>{{ table.columns }} column(s) | 0 rows</small></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  data() {
    return {
      tables: []
    }
  },
  methods: {
    // On click create button, show create menu
    buttonMouseUp(){
      document.querySelector("#view [view=dashboard] .create-menu").style.display = "block";
      document.querySelector("#view [view=dashboard] button.create .las.la-angle-down").style.display = "inline-block";
      document.querySelector("#view [view=dashboard] button.create .las.la-plus").style.display = "none";
    },
    handleEscKey(event) {
      if (event.key === "Escape" || event.key === "Esc") {
        document.querySelector("#view [view=dashboard] .create-menu").style.display = "none";
        document.querySelector("#view [view=dashboard] button.create .las.la-angle-down").style.display = "none";
        document.querySelector("#view [view=dashboard] button.create .las.la-plus").style.display = "inline-block";
      }
    }
  },
  async mounted() {
    window.addEventListener("keydown", this.handleEscKey);

    // Load tables from mysql
    try {
      const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php?controller=table&method=read&params=[]', {
        method: 'GET'
      });

      const result = await response.json();

      if (result.status === 'success') {
        this.tables = result.tables;
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.log("failed to load tables on dashboard - error: "+error.message);
    }
  },
  beforeUnmount() {
    // Remove keydown event listener to avoid memory leaks
    window.removeEventListener("keydown", this.handleEscKey);
  }
}
</script>

<style lang="scss" scoped>
  .dashboard-row {
    display: flex;
    flex-flow: row nowrap;

    .dashboard-cell {
      display: flex;
      flex-flow: column nowrap;
      flex: 1;
      max-width: 33.33%;
      border-radius: 3px;
      border: 1px solid darken($app-view-form-color, 25%);
      box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.15);
      text-align: left;
      background: $app-view-form-color;
      padding: 15px;

      hr {
        margin: 10px 0 !important;
      }

      .dashboard-cell-title {
        flex: 0;

        h1 {
          font-size: 22px;
          margin: 0;
        }
      }

      .dashboard-cell-content {
        flex: 1;

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
      }

      .dashboard-cell-list {

        li > div {
          display: flex;
          flex-flow: row nowrap;
          
        }

        small {
          opacity: 0.65;
          font-size: 0.75em;
          line-height: 22px;
    margin-left: 6px;
        }
      }
    }
  }

  button.create {
    i.las.la-angle-down {
      display: none;
    }
  }

  .create-menu {
    position: absolute;
    display: none;
    left: calc(50% - 75px);
    width: 150px;
    height: 200px;
    margin: 15px auto auto auto;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      background: #e6e6e6;
      border-radius: 3px;

      a {
        text-decoration: none;
      }

      & > a:first-child li {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      & > a:last-child li {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      li {
        font-size: 14px;
        font-weight: 400;
        color: #838383;
        padding: 2.5px 5px;
        text-align: left;
        cursor: pointer;

        i {
          font-size: 12px;
          margin: 0 5px 0 5px;
        }

        &:hover {
          background: rgb(62 111 161);
          color: #fefefe;
        }
      }
    }
  }
</style>


