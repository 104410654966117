import { createRouter, createWebHistory } from 'vue-router'
import CreateTable from '../views/create/CreateTable.vue';
import ViewTables from '../views/view/ViewTables.vue';
import CreateDataset from '../views/create/CreateDataset.vue';
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/view/tables',
    name: 'view-tables',
    component: ViewTables
  },
  {
    path: '/create/table',
    name: 'create-table',
    component: CreateTable
  },
  {
    path: '/create/dataset',
    name: 'create-dataset',
    component: CreateDataset
  }//,
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(!store.getters.isLoggedIn && to.path !== '/login')
    return next('/login');

  if (to.path === '/login' && store.getters.isLoggedIn)
    return next('/'); // Redirect logged-in users away from login
  
  next(); // Allow navigation
});

export default router
