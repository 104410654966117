import { createApp } from 'vue'
import App from './App.vue'
import Navbar from './components/Navbar.vue'
import router from './router'
import store from './store';

createApp(App)
  .component("Navbar", Navbar)
  .use(router)
  .use(store)
  .mount('#app')
