<template>
  <Navbar />
  <div id="view">
    <router-view/>
  </div>
</template>

<script>
  document.querySelector("title").innerHTML = "LTOMS Software (v"+process.env.VUE_APP_VERSION+")";
</script>

<style lang="scss">
body {
  margin: 0;

  #app {
    background: $app-background-color;

    &, #view {
      color: $app-text-color;
    }

    &, #view, #view button {
      font-family: "Red Hat Display", Helvetica, Arial, sans-serif;
      font-optical-sizing: auto;
      text-align: center;
    }

    hr {
      margin: 15px 0 15px 0;
      height: 1px;
      background: rgb(199, 199, 199);
      border: none;
    }

    [view] {
      background: $app-view-color;
      min-height: calc(100vh - ($navbar-height + 30px));
      padding: 15px;

      &[view=login] {
        min-height: calc(100vh - ($navbar-height - 10px));
      }

      button {
        $border-color: darken($ltoms-blue, 5%);
        background: $ltoms-blue;
        color: #efefef;
        border: 1px solid desaturate($border-color, 25%);
        padding: 4px 8px;
        font-weight: 500;
        cursor: pointer;
        font-size: 14px;

        &.hollow {
          color: $ltoms-blue-hollow-text !important;
          background: $ltoms-blue-hollow !important;

          &:hover {
            color: white !important;
            background: $ltoms-blue-hollow-text !important;
          }

          i {
            font-size: 18px;
          }
        }

        &.cell {
          font-size: 12px;
          padding: 1px 4px;
          border-radius: 2px;
          margin-left: auto;
        }

        i {
          font-size: 17px;
          line-height: 14px;
        }

        &:not(.icon) {
          i {
            position: relative !important;
            top: 2px;
            left: 2px;
          }
        }

        &:hover {
          background: darken($ltoms-blue, 10%);
        }

        &.icon {
          padding: 2px 3px 1px 4px !important;
          border: none !important;
          border-radius: 3px;
          margin-top: 3px;

          &.red {
            background: rgb(163, 0, 0) !important;
          }

          i {
            font-size: 15px !important;
          }
        }
      }

      .view-form {
        display: inline-block;
        max-width: 1280px;
        min-height: 100px;
        margin: 0 auto;
        background: $app-view-form-color;
        border-radius: 3px;
        border: 1px solid darken($app-view-form-color, 25%);
        box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.15);

        p.tip, .form-title p {
          text-align: left;
          font-size: 14px;
          opacity: 0.75;
          max-width: 600px;
        }

        p.tip {
          margin: 0 0 15px 0;
        }

        .form-title {
          display: flex;
          flex-flow: row nowrap;
          padding: 15px 0 0 0;

          p,h1,h2,h3,h4,h5,h6 {
            margin: 0;
            padding: 0px 15px;
          }

          h1,h2,h3,h4,h5,h6 {
            font-weight: 500;
            text-align: left;
            font-size: 24px;
          }

          &.ltoms-signage {
            display: block;
            background: #efefef;
            padding-bottom: 15px;
            text-align: center;
            width: 100%;
          }
        }

        .form-body {
          display: flex;
          flex-flow: column nowrap;
          padding: 15px;

          .form-row {
            flex: 1;
            display: flex;
            flex-flow: row nowrap;
          }

          .form-field {
            display: flex;
            flex-flow: column nowrap;
            justify-content: left;
            align-items: left;

            &:not(:first-of-type) {
              margin-left: 10px;
            }

            &.checkbox {
              flex-flow: row nowrap !important;
              align-items: center !important;
            }
          }

          .form-column {
            flex: 1;
            display: flex;
            flex-flow: column nowrap;
          }

          .form-wrap-row {
            flex: 1;
            display: flex;
            flex-flow: row wrap;
          }

          .form-wrap-column {
            flex: 1;
            display: flex;
            flex-flow: column wrap;
          }

          label {
            display: block;
            font-family: sans-serif;
            font-size: 12px;
            text-align: left;
            margin-bottom: 3px;
          }

          input, select {
            padding: 4px 6px;
            font-size: 14px;
            border-radius: 0px;
            background: rgb(245, 245, 245);
            border: 1px solid darken($app-view-form-color, 25%);

            &::placeholder {
              font-size: 12px;
            }

            &[readonly] {
              opacity: 0.75;
              cursor: not-allowed;
            }
          }

          input[type=checkbox] {
            display: inline-block;
            margin: 0;
          }
          
          label.checkbox-label {
            margin-left: 5px;
            display: inline-block;
            font-size: 14px;
            margin-bottom: 0;
            line-height: 10px;
          }

          select {
            font-size: 12px;

            &, option {
              cursor: pointer;
            }
          }

          input, button {
            display: block;
          }

          button {
            $border-color: lighten($ltoms-blue, 40%);
            background: $ltoms-blue;
            color: #efefef;
            border: 1px solid desaturate($border-color, 25%);
            padding: 4px 12px;
            font-weight: 500;
            cursor: pointer;
            font-size: 14px;

            &:hover {
              background: darken($ltoms-blue, 25%);
              color: darken(#efefef, 25%);
            }
          }
        }

        &.login {
          border: 1px solid rgb(82 143 207 / 39%);
        }
      }
    }
  }
}
</style>
